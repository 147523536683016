<template>
  <v-card :style="styleCustom">
    <loading-circle v-if="loading" />
    <v-card-title
      v-if="!loading"
      class="py-0 headline default--card-title white--text"
    >
      <v-row>
        <v-col cols="12" class="px-0 d-flex align-left justify-space-between">
          <span
            class="px-0 ml-2 text-left"
            style="color: #2f5677; font-size: 16px; font-weight: 500"
          >
            Activities
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row class="py-2 flex align-center justify-center text-center w-full">
        <v-btn
          class="ml-3 text-center"
          small
          rounded
          :style="styleCustomButton"
          :color="
            isSperm == null && isEggs == null && isEmbryos == null
              ? '#DFDAD9'
              : '#A3C1B6'
          "
          light
          @click="$emit('openInitateTransport')"
        >
          Initiate Transport
        </v-btn>
      </v-row>
      <v-row
        class="flex align-center justify-center text-center w-full"
        style="margin-bottom: 10px"
      >
        <v-btn
          small
          rounded
          :style="styleCustomButton"
          class="ml-3 text-center"
          :color="
            isSperm == null && isEggs == null && isEmbryos == null
              ? '#DFDAD9'
              : 'red'
          "
          dark
          @click="$emit('openDiscontinueService')"
        >
          Discontinue Service
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingCircle from '../LoadingCircle.vue';
import device from '../../utils/device';
export default {
  components: { LoadingCircle },
  name: 'ActivitiesCard',
  props: ['loading', 'isSperm', 'isEggs', 'isEmbryos'],
  data() {
    return {
      styleCustom:
        device() === 'mobile'
          ? {
              width: '85vw',
              minHeight: '100px',
              'margin-bottom': '15px'
            }
          : {
              width: '14vw',
              minHeight: '100px'
            },
      styleCustomButton:
        device() === 'mobile'
          ? {
              height: '35px',
              'font-size': '2.7vw',
              'margin-top': '10px',
              width: '100%'
            }
          : {
              height: '35px',
              'font-size': '0.8vw',
              'margin-top': '10px',
              'min-width': '100px'
            }
    };
  },
  methods: {}
};
</script>

<style scoped>
.topbar-fixed {
  position: relative;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

table tbody tr td {
  height: 20px !important;
}
</style>
