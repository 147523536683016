<template>
  <v-card :style="styleCustom">
    <loading-circle v-if="loading" />
    <v-card-title
      v-if="!loading"
      class="py-0 headline default--card-title white--text"
    >
      <v-row>
        <v-col cols="12" class="px-0 d-flex align-left justify-space-between">
          <span
            class="px-0 ml-2 text-left"
            style="color: #2f5677; font-size: 16px; font-weight: 500"
          >
            Current Balance
          </span>
          <span
            class="px-0 mr-5 text-right"
            style="color: black; font-size: 18px; font-weight: 600"
          >
            {{ balance ? formatMoney(balance) : '$0.00' }}
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <div v-if="!checkMobile">
        <v-col
          cols="12"
          class="py-6 flex align-center text-center"
          style="
            border-top: 2px solid gray;
            justify-content: space-between;
            display: flex;
          "
        >
          <v-btn
            class="text-center bottom-fixed"
            small
            rounded
            :style="styleCustomButtonPayNow"
            color="#DECA76"
            light
            :disabled="!balance || balance < 0"
            @click="$emit('openPayNow')"
          >
            Pay now
          </v-btn>
          <v-btn
            small
            rounded
            :style="styleCustomButtonMP"
            class="ml-3 text-center bottom-fixed"
            color="primary"
            dark
            @click="$emit('openManage')"
          >
            Manage Payment Methods
          </v-btn>
        </v-col>
      </div>
      <div class="py-6 flex align-center text-center" v-else>
        <v-row class="py-2 flex align-center justify-center text-center w-full">
          <v-btn
            class="text-center bottom-fixed"
            small
            rounded
            :style="styleCustomButtonPayNow"
            color="#DECA76"
            light
            :disabled="!balance || balance < 0"
            @click="$emit('openPayNow')"
          >
            Pay now
          </v-btn>
        </v-row>
        <v-row class="py-2 flex align-center justify-center text-center w-full">
          <v-btn
            small
            rounded
            :style="styleCustomButtonMP"
            class="ml-3 text-center bottom-fixed"
            color="primary"
            dark
            @click="$emit('openManage')"
          >
            Manage Payment Methods
          </v-btn>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingCircle from '../LoadingCircle.vue';
import device from '../../utils/device';
export default {
  components: { LoadingCircle },
  name: 'BalanceCard',
  props: ['balance', 'loading'],
  data() {
    return {
      styleCustom:
        device() === 'mobile'
          ? {
              width: '85vw',
              minHeight: '100px',
              'margin-bottom': '15px'
            }
          : {
              width: '25vw',
              minHeight: '100px'
            },
      styleCustomButtonPayNow:
        device() === 'mobile'
          ? {
              height: '35px',
              'font-size': '2.0vw',
              'margin-top': '10px',
              'margin-left': '10px',
              width: '95%'
            }
          : {
              height: '35px',
              'font-size': '0.8vw',
              'margin-top': '10px',
              'min-width': '95px'
            },
      styleCustomButtonMP:
        device() === 'mobile'
          ? {
              height: '35px',
              'font-size': '2.0vw',
              'margin-top': '5px',
              width: '95%'
            }
          : {
              height: '35px',
              'font-size': '0.8vw',
              'margin-top': '10px'
            }
    };
  },
  methods: {
    formatMoney(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      if (!amount) return '$0.00';
      return formatter.format(amount);
    }
  },
  computed: {
    checkMobile() {
      return device() === 'mobile' ? true : false;
    }
  }
};
</script>

<style scoped>
.topbar-fixed {
  position: relative;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.bottom-fixed {
  margin-bottom: 0;
}
table tbody tr td {
  height: 20px !important;
}
</style>
