<template>
  <div>
    <v-btn
      :style="styleCustomFont"
      color="primary white--text elevation-0"
      @click="getTransactionReceipt(transaction.id)"
      :loading="loading"
      :disabled="transaction.success !== 'Success'"
    >
      {{ text }}
    </v-btn>

    <div v-show="false">
      <div id="target" v-html="dataHTML"></div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import { api, auth } from '../../sharedPlugin';
import device from '../../utils/device';
export default {
  props: ['text', 'transaction', 'transactionsLoading'],
  data: () => ({
    styleCustomFont:
      device() === 'mobile'
        ? {
            'font-size': '1.8vw',
            height: '25px'
          }
        : {
            'font-size': '0.6vw',
            height: '25px'
          },
    dataReceipt: null,
    dataHTML: null,
    buttonLoading: false
  }),
  computed: {
    loading() {
      return this.transactionsLoading || this.buttonLoading;
    }
  },
  methods: {
    async getTransactionReceipt(id) {
      this.buttonLoading = true;
      await api.CardConnect.getTransactionReceipt(id, true)
        .then(async (res) => {
          this.dataReceipt = res;
          this.mountHTML(res);
          await this.initiateDownload();
          this.$store.commit('showSnackbar', {
            message: 'Receipt download successfully',
            color: 'success'
          });
        })
        .catch((err) => {
          this.$store.commit('showSnackbar', {
            message: err.message,
            color: 'error'
          });
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
    async initiateDownload() {
      let pdf;
      const doc = new jsPDF('p', 'pt', 'a4', true);
      const a4Width = Number(doc.internal.pageSize.getWidth());
      await doc.html(document.querySelector('#target'), {
        callback: function (doc) {
          pdf = doc.output('datauristring');
        },
        x: 10,
        y: 10,
        width: a4Width - 20,
        windowWidth: a4Width
      });
      const link = document.createElement('a');
      link.href = pdf;
      link.download = `receipt-${this.transaction.id}.pdf`;
      link.click();
    },
    mountHTML(data) {
      const mock = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="text/html; charset=windows-1252">
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=Edge">
      <!--<![endif]-->
      <!--[if (gte mso 9)|(IE)]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
      <!--[if (gte mso 9)|(IE)]>
  <style type="text/css">
    body {width: 600px;margin: 0 auto;}
    table {border-collapse: collapse;}
    table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
    img {-ms-interpolation-mode: bicubic;}
  </style>
<![endif]-->
      <style type="text/css">
    body, p, div {
      font-family: arial,helvetica,sans-serif;
      font-size: 14px;
    }
    body {
      color: #000000;
    }
    body a {
      color: #1188E6;
      text-decoration: none;
    }
    p { margin: 0; padding: 0; }
    table.wrapper {
      width:100% !important;
      table-layout: fixed;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
      -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }
    img.max-width {
      max-width: 100% !important;
    }
    .column.of-2 {
      width: 50%;
    }
    .column.of-3 {
      width: 33.333%;
    }
    .column.of-4 {
      width: 25%;
    }
    ul ul ul ul  {
      list-style-type: disc !important;
    }
    ol ol {
      list-style-type: lower-roman !important;
    }
    ol ol ol {
      list-style-type: lower-latin !important;
    }
    ol ol ol ol {
      list-style-type: decimal !important;
    }
    @media screen and (max-width:480px) {
      .preheader .rightColumnContent,
      .footer .rightColumnContent {
        text-align: left !important;
      }
      .preheader .rightColumnContent div,
      .preheader .rightColumnContent span,
      .footer .rightColumnContent div,
      .footer .rightColumnContent span {
        text-align: left !important;
      }
      .preheader .rightColumnContent,
      .preheader .leftColumnContent {
        font-size: 80% !important;
        padding: 5px 0;
      }
      table.wrapper-mobile {
        width: 100% !important;
        table-layout: fixed;
      }
      img.max-width {
        height: auto !important;
        max-width: 100% !important;
      }
      a.bulletproof-button {
        display: block !important;
        width: auto !important;
        font-size: 80%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .columns {
        width: 100% !important;
      }
      .column {
        display: block !important;
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .social-icon-column {
        display: inline-block !important;
      }
    }
  </style>
    <style>
      @media screen and (max-width:480px) {
        table\0 {
          width: 480px !important;
          }
      }
    </style>
      <!--user entered Head Start--><!--End Head user entered-->
    </head>
    <body>
      <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#FFFFFF;">
        <div class="webkit">
          <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#FFFFFF">
            <tbody><tr>
              <td valign="top" bgcolor="#FFFFFF" width="100%">
                <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                  <tbody><tr>
                    <td width="100%">
                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                        <tbody><tr>
                          <td>
                            <!--[if mso]>
    <center>
    <table><tr><td width="600">
  <![endif]-->
                                    <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:600px;" align="center">
                                      <tbody><tr>
                                        <td role="modules-container" style="padding:0px 0px 0px 0px; color:#000000; text-align:left;" bgcolor="#FFFFFF" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
    <tbody><tr>
      <td role="module-content">
        <p>Your Fertility Services Provider.</p>
      </td>
    </tr>
  </tbody></table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="e95e968d-be17-4f1e-9287-4e26a5666436">
    <tbody>
      <tr>
        <td height="100%" valign="top" role="module-content">



		<title>Prelude Fertility Invoice</title>

		<style>
			.invoice-box {
				max-width: 800px;
				margin: auto;
				padding: 30px;
				border: 1px solid #eee;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
				font-size: 16px;
				line-height: 24px;
				font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
				color: #555;
			}

			.invoice-box table {
				width: 100%;
				line-height: inherit;
				text-align: left;
			}

			.invoice-box table td {
				padding: 5px;
				vertical-align: top;
			}

			.invoice-box table tr td:nth-child(2) {
				text-align: right;
			}

			.invoice-box table tr.top table td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.top table td.title {
				font-size: 45px;
				line-height: 45px;
				color: #333;
			}

			.invoice-box table tr.information table td {
				padding-bottom: 40px;
			}

			.invoice-box table tr.heading td {
				background: #eee;
				border-bottom: 1px solid #ddd;
				font-weight: bold;
			}

			.invoice-box table tr.details td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.item td {
				border-bottom: 1px solid #eee;
			}

			.invoice-box table tr.item.last td {
				border-bottom: none;
			}

			.invoice-box table tr.total td:nth-child(2) {
				border-top: 2px solid #eee;
				font-weight: bold;
			}

			@media only screen and (max-width: 600px) {
				.invoice-box table tr.top table td {
					width: 100%;
					display: block;
					text-align: center;
				}

				.invoice-box table tr.information table td {
					width: 100%;
					display: block;
					text-align: center;
				}
			}

			/** RTL **/
			.invoice-box.rtl {
				direction: rtl;
				font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
			}

			.invoice-box.rtl table {
				text-align: right;
			}

			.invoice-box.rtl table tr td:nth-child(2) {
				text-align: left;
			}
		</style>
		<div class="invoice-box">
			<table cellpadding="0" cellspacing="0">
				<tbody><tr class="top">
					<td colspan="2">
						<table>
							<tbody><tr>
								<td class="title">
									<img src="${data.logoLink}" style="width: 100%; max-width: 300px">
								</td>

								<td>
									  Ref #: ${data.reference}<br>
                    Created: ${data.formattedTransDate}<br>
                    NPI: ${data.cryoNpi}<br>
								</td>
							</tr>
						</tbody></table>
					</td>
				</tr>

				<tr class="information">
					<td colspan="2">
						<table>
							<tbody><tr>
								<td>
									${data.facility}<br>
									${data?.facilityAddress1 ?? ''}<br>
									${data?.facilityAddress2 ?? ''}<br>
                  ${data?.facilityCity ?? ''}, ${data?.facilityState ?? ''} ${
        data?.facilityZip ?? ''
      }
								</td>

								<td>
									${data.patientName}<br>
                  ${data?.patientEmail ?? ''}<br>
                  ${data?.patientAddress1 ?? ''}<br>
                  ${data?.patientAddress2 ?? ''}<br>
                  ${data?.patientCity ?? ''}, ${data?.patientState ?? ''} ${
        data?.patientZip ?? ''
      }
								</td>
							</tr>
						</tbody></table>
					</td>
				</tr>

				<tr class="heading">
					<td>Payment Method</td>

					<td>Amount </td>
				</tr>

				<tr class="details">
					<td>${data.description}</td>

					<td>(${data.formattedAmount})</td>
				</tr>

				<tr class="heading">
					<td>Item</td>

					<td>Price</td>
				</tr>

				<tr class="item">
					<td>${data?.notes ?? ''} - Timeframe: ${data?.startingDate ?? ''} to ${
        data?.endingDate ?? ''
      } - CPT: ${data?.cptCode ?? ''}</td>

					<td>(${data.formattedAmount})</td>
				</tr>

				<tr class="total">
					<td></td>

					<td>Total Paid: (${data.formattedAmount})</td>
				</tr>
			</tbody></table>
		</div>

</td>
      </tr>
    </tbody>
  </table></td>
                                      </tr>
                                    </tbody></table>
                                    <!--[if mso]>
                                  </td>
                                </tr>
                              </table>
                            </center>
                            <![endif]-->
                          </td>
                        </tr>
                      </tbody></table>
                    </td>
                  </tr>
                </tbody></table>
              </td>
            </tr>
          </tbody></table>
        </div>
      </center>
  </body></html>`;

      this.dataHTML = mock;
    }
  }
};
</script>
