<template>
  <v-container>
    <v-dialog v-model="dialog" width="50%">
      <v-card light clas="justify-center" rounded raised>
        <!-- TODO, switch inputs to the custom input components made in ticket #6894 -->
        <v-toolbar dark color="primary" flat>
          <v-card-title>Please confirm choice below</v-card-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-form class="px-8">
            <v-radio-group v-model="type" mandatory column>
              <v-row>
                <v-radio color="red" value="All">
                  <template v-slot:label>
                    <div>
                      I/We authorize the lab to discard of
                      <strong class="red--text">ALL</strong>
                      tissues associated with
                      <strong class="red--text">
                        ALL FREEZE or RECEIVE DATES.
                      </strong>
                    </div>
                  </template>
                </v-radio>
                <v-radio color="red" value="Partial">
                  <template v-slot:label>
                    <div>
                      I/We authorize the lab to discard tissues associated with
                      <strong class="red--text">
                        SPECIFIC FREEZE or RECEIVE DATES.
                      </strong>
                    </div>
                  </template>
                </v-radio>
              </v-row>
            </v-radio-group>

            <v-checkbox color="red" v-model="isAgree">
              <template v-slot:label>
                I/We understand this decision will result in my/our Inability to
                use my/our cryopreserved specimens in future reproductive
                cycles.
              </template>
            </v-checkbox>
            <div>
              <p>
                This is a request to discontinue cryo preservation services and
                dispose of preserved tissue, the Cryo Service Team will contact
                you to verify your identity before processing this request.
              </p>
            </div>
          </v-form>
        </v-card-actions>
        <v-card-actions>
          <v-col md="2">
            <v-btn block dark color="primary" @click="close">Cancel</v-btn>
          </v-col>
          <v-col>
            <v-dialog v-model="confirmDialog" persistent max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isAgree"
                >
                  Submit
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">Are you sure?</v-card-title>
                <v-card-text>
                  By clicking submit, you are authorizing the Cryo Team to
                  contact you to verify your identity, request written consent,
                  and process your request.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="confirmDialog = false">
                    Close
                  </v-btn>
                  <v-btn
                    color="red darken-1"
                    :loading="loading"
                    text
                    @click="submit()"
                    :disabled="!isAgree"
                  >
                    Yes, Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  emits: ['goBack'],
  data() {
    return {
      dialog: false,
      loading: false,
      confirmDialog: false,
      isAgree: false
    };
  },
  methods: {
    submit() {
      this.$emit('submit');
      this.close();
    },
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.confirmDialog = false;
    }
  }
};
</script>
