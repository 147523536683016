<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row cols="12">
          <v-col cols="12">
            <v-card
              flat
              class="row flex justify-center justify-space-between"
              align="center"
              style="background-color: transparent"
            >
              <AccountCard :loading="this.balanceLoading"></AccountCard>
              <BalanceCard
                :balance="this.balance"
                :loading="this.balanceLoading"
                @openManage="openManage"
                @openPayNow="payAllInvoice"
              ></BalanceCard>
              <ActivitiesCard
                :isSperm="sperm"
                :isEggs="egg"
                :isEmbryos="embryo"
                :loading="this.balanceLoading"
                @openDiscontinueService="openDiscontinueService"
                @openInitateTransport="openInitateTransport"
              ></ActivitiesCard>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog :width="manageWidth" scrollable v-model="dialog">
          <Manage
            :key="keyManageCards"
            @goBack="dialog = !dialog"
            :cards="cards"
            @refetchCards="getCardConnectProfile"
            v-on="$listeners"
            @showCardInfo="showCardInfo"
          />
        </v-dialog>

        <PayInvoice
          @sucessPayment="reloadAll"
          ref="payInvoice"
          :cards="cards"
          :invoices="selectedInvoices"
          :invoice="selectedInvoice"
        />
        <Disposition ref="disposition" />
        <InitiateTransport
          :isSperm="sperm"
          :isEggs="egg"
          :isEmbryos="embryo"
          @submit="requestInitateTransport"
          :loading="initiateTransportLoading"
          ref="initiateTransport"
        />
        <DiscontinueService
          :isSperm="sperm"
          :isEggs="egg"
          :isEmbryos="embryo"
          @submit="requestDiscontinueService"
          :loading="descontinueServiceLoading"
          ref="descontinueService"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Disposition from '@/components/cryo/Disposition.vue';
import Manage from '@/components/cryo/Manage.vue';
import PayInvoice from '@/components/cryo/PayInvoice.vue';
import InitiateTransport from '@/components/cryo/InitiateTransport.vue';
import DiscontinueService from '@/components/cryo/DiscontinueService.vue';
import AccountCard from '../../components/cryo/AccountCard.vue';
import BalanceCard from '../../components/cryo/BalanceCard.vue';
import ActivitiesCard from '../../components/cryo/ActivitiesCard.vue';
import device from '../../utils/device';
import _ from 'lodash';
import moment from 'moment';
import { api, auth } from '../../sharedPlugin';

const Swal = require('sweetalert2');

export default {
  props: ['reloadBalance'],
  components: {
    Manage,
    Disposition,
    PayInvoice,
    AccountCard,
    BalanceCard,
    ActivitiesCard,
    InitiateTransport,
    DiscontinueService
  },
  data() {
    return {
      manageWidth: device() === 'mobile' ? '100%' : '50%',
      keyManageCards: new Date().getTime(),
      snackbar: false,
      next_billing_at: null,
      selectedInvoice: null,
      payInoviceModal: false,
      dialog: false,
      method: null,
      selectedInvoices: null,
      balanceLoading: false,
      balance: 0,
      loading: false,
      egg: null,
      sperm: null,
      embryo: null
    };
  },
  watch: {
    reloadBalance() {
      this.getBalance();
    },
    tab(value) {
      if (value == 0) {
        this.getInventory();
      }
    },
    dialog() {
      if (this.dialog) {
        this.getCardConnectProfile();
      }
    }
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    showCardInfo() {
      this.dialog = !this.dialog;
    },
    openManage() {
      if (this.showAddCard) {
        this.showAlert();
      } else {
        this.dialog = !this.dialog;
      }
    },
    openInitateTransport() {
      if (this.showAddCard) {
        this.showAlert();
      } else {
        if (this.egg == null && this.sperm == null && this.embryo == null) {
          Swal.fire({
            title: 'Cryo Service',
            text: "You don't have freezes",
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ok'
          }).then((result) => {});
        } else {
          this.$refs.initiateTransport.show();
          this.initiateTransportModal = true;
        }
      }
    },
    showAlert() {
      Swal.fire({
        title: 'Cryo Service',
        text: 'You need to add a payment method before proceeding!',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ok'
      }).then((result) => {});
    },
    async requestInitateTransport() {
      this.initiateTranportLoading = true;
      await api.Cryo.initiateTransport(this.$route.params.category)
        .then(() => {
          this.$store.commit('showSnackbar', {
            message: 'Initiate transport requested.',
            color: 'success'
          });
          this.initiateTransportModal = false;
        })
        .finally(() => (this.initiateTranportLoading = false));
    },
    openDiscontinueService() {
      if (this.showAddCard) {
        this.showAlert();
      } else {
        if (this.egg == null && this.sperm == null && this.embryo == null) {
          Swal.fire({
            title: 'Cryo Service',
            text: "You don't have freezes",
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ok'
          }).then((result) => {});
        } else {
          this.$refs.descontinueService.show();
          this.descontinueServiceModal = true;
        }
      }
    },
    async requestDiscontinueService() {
      this.descontinueServiceLoading = true;
      await api.Cryo.descontinueService(this.$route.params.category)
        .then(() => {
          this.$store.commit('showSnackbar', {
            message: 'Discontinue service requested.',
            color: 'success'
          });
          this.descontinueServiceModal = false;
        })
        .finally(() => (this.descontinueServiceLoading = false));
    },
    async getInvoices() {
      await api.Cryo.getCryoInvoices()
        .then((res) => {
          this.invoicesItems = res.map((item) => ({
            invoice: item.id,
            details: item.description,
            invoicedAmount: item.total,
            paid: item.paid,
            balancedue: item.balancedue,
            dueDate: item.duedate,
            status: item.status,
            type: item.type,
            option:
              parseFloat(item.balancedue) > 0 && item.status != 'PAID'
                ? true
                : false
          }));

          if (
            this.$route.params.id &&
            this.$route.path.indexOf('/cryo/invoice/') >= 0
          ) {
            this.goPayment();
          }
        })
        .finally(() => {});
    },
    goPayment() {
      let invoice = this.invoicesItems.find(
        (i) => i.invoice.toString() === this.$route.params.id.toString()
      );
      if (!invoice) {
        return;
      }
      this.$modal.show({
        text: `Proceed to pay Invoice: ${this.$route.params.id} Amount:  $${
          invoice && invoice.invoicedAmount
            ? invoice.invoicedAmount.toFixed(2)
            : 0.0
        }`,
        buttons: [
          {
            text: 'Cancel',
            color: 'grey black--text',
            onAction: () => {
              this.$router.push({ path: '/cryo' });
              return;
            }
          },
          {
            text: 'Pay now',
            color: 'primary white--text',
            onAction: async () => {
              if (invoice) {
                this.payInvoice(invoice);
              } else {
                this.$store.commit('showSnackbar', {
                  message: 'invoice not found',
                  color: 'error'
                });
              }
            }
          }
        ]
      });
    },
    reloadAll() {
      this.getFreezes();
      this.getCardConnectProfile();
      this.getInvoices();
      this.getBalance();
    },
    async getFreezes() {
      this.freezeLoading = true;
      await api.Cryo.getCryoInventory()
        .then((res) => {
          this.freezeLoading = true;

          this.freezeItems = res.map((item) => ({
            FreezeDate: item.FreezeDate
              ? moment.utc(item.FreezeDate).format('MM/DD/YYYY')
              : '',
            InventoryDate: item.InventoryDate
              ? moment.utc(item.InventoryDate).format('MM/DD/YYYY')
              : '',
            source: item.source,
            type: item.type,
            next_billing_at: item.next_billing_at
              ? moment.utc(item.next_billing_at).format('MM/DD/YYYY')
              : '',
            billingprofile: item.billingprofile,
            amount:
              item.amount && item.discount_amount
                ? `$ ${(
                    parseFloat(item.amount) - parseFloat(item.discount_amount)
                  ).toFixed(2)}`
                : item.amount
                ? `$ ${item.amount}`
                : `$ 0.00`,
            active_pbp: item.active_pbp,
            location: item.location
          }));

          let embryo = this.freezeItems.filter((item) => {
            return item.type === 'Embryo';
          });

          this.embryo = embryo && embryo.length > 0 ? embryo[0] : null;
          let sperm = this.freezeItems.filter((item) => {
            return item.type === 'Sperm';
          });

          this.sperm = sperm && sperm.length > 0 ? sperm[0] : null;

          let egg = this.freezeItems.filter((item) => {
            return item.type === 'Egg';
          });

          this.egg = egg && egg.length > 0 ? egg[0] : null;
        })
        .finally(() => (this.freezeLoading = false));
    },
    reduceObject(value, key) {
      return value
        .map((item) => {
          return parseFloat(item[key]);
        })
        .reduce(
          (previous_value, current_value) => previous_value + current_value
        );
    },
    async getBalance() {
      this.balanceLoading = true;
      await api.Cryo.getCryoBalance()
        .then((res) => {
          if (res.length > 0) {
            this.balance = res[0]?.balance;
          }
        })
        .finally(() => (this.balanceLoading = false));
    },
    payAllInvoice() {
      if (this.showAddCard) {
        this.showAlert();
      } else {
        this.selectedInvoices = this.invoicesItems.filter((item) => {
          return item.option === true;
        });

        this.selectedInvoice = this.selectedInvoices[0];
        this.selectedInvoice.amount = this.balance;

        this.$refs.payInvoice.show();
      }
    },
    async getCardConnectProfile() {
      this.loading = true;
      this.cards = [];
      await api.CardConnect.getCardConnectProfile()
        .then(async (res) => {
          if (res.length > 0) {
            this.showAddCard = false;

            const cardProfiles = [...res];
            cardProfiles.forEach((cardProfile) => {
              this.cards.push({
                ...cardProfile,
                expiry: cardProfile.expires,
                account:
                  cardProfile.token.substr(1, 2) +
                  'XX XXXX XXXX ' +
                  cardProfile.last_four,
                cardType: cardProfile.description,
                lastfour: cardProfile.last_four
              });
            });
          } else {
            this.showAddCard = true;
          }
        })
        .catch((err) => alert(err))
        .finally(() => {
          this.loading = false;
        });
    }
  },
  async mounted() {
    this.getFreezes();
    this.getBalance();
    this.getInvoices();
    this.getCardConnectProfile();
  }
};
</script>
