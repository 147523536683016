<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Header></Header>
        <v-card
          v-if="loading"
          tile
          class="row"
          style="width: 100%; height: 200px"
        >
          <loading-circle v-if="loading" />
        </v-card>

        <div v-else>
          <NoCard
            :showAddCard="showAddCard"
            @sucessAddCard="showAddCard = !showAddCard"
          />
          <v-card v-if="!showAddCard" tile class="row" style="width: 100%">
            <v-tabs v-model="tab">
              <v-tab key="transactions">Payment History</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="width: 100%">
              <v-tab-item key="transactions">
                <v-card-text>
                  <v-data-table
                    class="table-freezes"
                    :headers="transactionsHeaders"
                    :items="transactionsItems"
                    :loading="transactionsLoading"
                    :items-per-page="10"
                  >
                    <template v-slot:[`item.date`]="{ item }">
                      {{ item.date }}
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                      {{ item.description }}
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                      {{ item.amount ? formatMoney(item.amount) : '$0.00' }}
                    </template>
                    <template v-slot:[`item.invoice`]="{ item }">
                      {{ item.invoice }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      {{ item.status }}
                    </template>

                    <template v-slot:[`item.id`]="{ item }">
                      <ButtonReceipt
                        :transaction="item"
                        :transacxtionLoading="transactionsLoading"
                        text="Download Receipt"
                      />
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingCircle from '@/components/LoadingCircle.vue';
import ButtonReceipt from '@/components/cryo/ButtonReceipt.vue';
import Header from './Header.vue';
import _ from 'lodash';
import moment from 'moment';
import { api, auth } from '../../sharedPlugin';
import NoCard from './NoCard.vue';
export default {
  props: ['cards'],
  components: {
    LoadingCircle,
    Header,
    NoCard,
    ButtonReceipt
  },
  data() {
    return {
      showAddCard: false,
      tab: 0,
      transactionsHeaders: [
        { text: 'Date Paid', value: 'date', sortable: false },
        { text: 'Detail', value: 'description', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'success', sortable: false },
        { text: 'Invoice Number', value: 'invoice', sortable: false },
        { text: 'Options', value: 'id', sortable: false, align: 'center' }
      ],
      transactionsLoading: false,
      transactionsItems: [],
      loading: false,
      selectedInvoices: null,
      selectedInvoice: null
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    async getTransactions() {
      this.transactionsLoading = true;
      await api.Cryo.getCryoTransactions()
        .then((res) => {
          this.transactionsItems = res.map((item) => ({
            date: item.datetime
              ? moment.utc(item.datetime).format('MM/DD/YYYY')
              : '',
            amount: item?.amount,
            type: item.type,
            invoice: item.invoice ? item.invoice : '-',
            description: item.notes,
            id: item.id,
            success: item.success
              ? item.success == 1
                ? 'Success'
                : 'Failed'
              : 'Failed'
          }));
        })
        .finally(() => (this.transactionsLoading = false));
    },
    async getTransactionReceipt(id) {
      await api.CardConnect.getTransactionReceipt(id)
        .then((res) => {
          this.$store.commit('showSnackbar', {
            message: 'Receipt sent successfully',
            color: 'success'
          });
        })
        .catch((err) => {
          this.$store.commit('showSnackbar', {
            message: err.message,
            color: 'error'
          });
        });
    },
    formatMoney(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      if (!amount) return '$0.00';
      return formatter.format(amount);
    }
  },
  created() {
    if ((this.cards && this.cards.length === 0) || this.cards === null) {
      this.showAddCard = true;
    }
    this.getTransactions();
  }
};
</script>

<style>
.table-freezes .v-data-table__wrapper table tbody tr td {
  height: 32px !important;
  font-size: 0.7rem !important;
}
.table-freezes .v-data-table__wrapper .v-data-table-header tr th {
  color: #000000 !important;
}
.v-data-table.row-height-50 td {
  height: 18px !important;
}
</style>
