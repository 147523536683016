<template>
  <v-card :style="styleCustom">
    <loading-circle v-if="loading" />
    <v-card-title
      v-if="!loading"
      class="py-0 headline default--card-title white--text"
    >
      <v-row>
        <v-col cols="12" class="px-0 d-flex align-left justify-space-between">
          <span
            class="px-0 ml-2 text-left"
            style="
              color: #2f5677;
              font-size: 16px !important;
              font-weight: 500 !important;
            "
          >
            Account
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading" style="margin-bottom: 10px">
      <v-row class="py-2 flex align-center justify-center text-center">
        <v-img src="../../assets/user.png" max-width="70px" />
      </v-row>

      <v-row class="flex align-center justify-center text-center">
        <span class="text-center" style="color: black">{{ userEmail }}</span>
      </v-row>
      <v-row class="flex align-center justify-center text-center">
        <span class="text-center" style="color: black">{{ clinicName }}</span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { auth } from '../../sharedPlugin';
import LoadingCircle from '../LoadingCircle.vue';
import device from '../../utils/device';
export default {
  components: { LoadingCircle },
  name: 'AccountCard',
  props: ['loading'],
  data() {
    return {
      styleCustom:
        device() === 'mobile'
          ? {
              width: '85vw',
              minHeight: '100px',
              'margin-bottom': '15px'
            }
          : {
              width: '14vw',
              minHeight: '100px'
            }
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    clinicName() {
      return this.user?.clinic;
    },
    userEmail() {
      return this.user?.email;
    }
  },
  methods: {}
};
</script>

<style scoped>
.topbar-fixed {
  position: relative;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
table tbody tr td {
  height: 20px !important;
}
</style>
